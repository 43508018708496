import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { Spinner } from "@thedgbrt/logology-ui-components"

const SigninSuccess = () => {
  useEffect(() => {
    navigate("/myaccount/proposals/", { replace: true })
  }, [])

  return <Spinner />
}

export default SigninSuccess
